/* Typography */

// Font families
@headings-font-family: 'DM Sans', sans-serif;
@headings-font-weight: bold;
@headings-color: @black;

@font-family-base: 'Roboto', sans-serif;
@font-size-base: 14px;

// Major third 1.25, Minor third 1.2, Major second 1.125
@scale: 1.15;
@font-sizes:
  h1 @font-size-base * pow(@scale, 5),
  h2 @font-size-base * pow(@scale, 4),
  h3 @font-size-base * pow(@scale, 3),
  h4 @font-size-base * pow(@scale, 2),
  h5 @font-size-base * @scale,
  h6 @font-size-base;

@line-height-base: 1.5;
@line-height-headings: 1.3;

/* Colors */

// Theme Color Palette
@primary: #B1F946;
@primary-dark: #86DA07;
@primary-darker: #78C406;
@navy: #0F2137;
@dim-mid-light: #DEEDE2;
@dim-mid: #054C2E;
@dim-dark: #598D0C;
@dim-darker: #023512;
@text: #343D48;
@secondary-text: #8E8E93;
@neutral-gray: #F2F2EA;
@neutral-vanilla: #FCF9F2;
@teal-darker: #78A183;
@teal-dark: #AED1B8;
@teal-mid: #F1FFE5;
@white: #FFFFFF;
@black: #000000;
@secondary: #ff8b8b;
@grey: @B300;
@light-grey: #E5E5EA;

@anchor-color: @primary-dark;
@anchor-color-hover: @primary-darker;

// Spectrum

@red: #f44336;
@orange: #ffb300;
@orange-darker: #c84b28;
@yellow: #ffca28;
@green: #4caf50;
@cyan: #00bcd4;
@blue: #2196f3;
@violet: #673ab7;
@black: #0f2137;

// Replace the auxiliary color, based on radix-ui palette

@green: #4cc38a; // Success
@blue: #52a9ff; // Info
@yellow: @orange-darker; // Warning
@red: #ff6369; // Error
@red-lightest: #ffe3e4;

// Warning colors

@warning-warm: #BF8162;
@warning-light: #FFD580;
@warning-lighter: #fff6e8;
@warning-dark: #ff9800;

// Ripple
@enable-ripple-effect: false;
