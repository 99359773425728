@import 'abstracts/variables';
@import 'ProductNavigation.module';
@import 'PageLayout.module';
@import 'abstracts/helpers';

@header-transition: 0.5s;
@mobile-menu-drawer-base-width: 390px;

.header {
  @shadow-y: 7.5px;

  position: sticky;
  top: 0;
  z-index: @zindex-navbar-fixed;
  background-color: @header-background;
  border-bottom: 1px solid var(--rs-divider-border);
  box-shadow: 0 @shadow-y @shadow-y -@shadow-y rgba(darken(@primary, 40%), 0.1);
  transition: @header-transition;

  .internal-cookie {
    padding: 0 @spacer;
    border-left: 1px solid @teal-dark;
  }

  @media @below-lg {
    padding: 0;
    box-shadow: unset;
  }

  .header-divider {
    margin: 0;
  }
}

.active-category-label {
  color: @teal-dark;
  font-weight: @headings-font-weight;
}

// Default styles apply for tablet view
// @below-sm media queries override styles for mobile view
.mobile-menu-content-styles {
  width: 100% !important; // Override default rs-drawer width
  max-width: @mobile-menu-drawer-base-width;

  @media @below-sm {
    max-width: unset; // Make full width on mobile
  }

  :global {
    .rs-drawer-content {
      max-width: @mobile-menu-drawer-base-width - 40; // Magic number - offset to match figma design
      right: 0;
      box-shadow: -9px 0 15px 3px rgba(126, 126, 126, 0.12); // Copied from Figma design

      @media @below-sm {
        max-width: unset;
        right: unset;
        box-shadow: unset;
        width: 100% !important;
      }
    }
  }

  a {
    color: @text;
  }

  svg {
    color: @B600;
    font-size: 1.5em; // Set the icon size
  }

  .active {
    font-weight: @headings-font-weight;
  }
}

.mobile-categories-menu {
  width: 100% !important; // Override default rs-drawer width
  max-width: @mobile-menu-drawer-base-width;

  @media @below-sm {
    max-width: unset; // Make full width on mobile
  }

  &-items-container {
    .mobile-menu-content-styles;
  }

  :global(.rs-input-group),
  :global(.rs-input) {
    border-radius: 100px;
  }
}

.mobile-category-picker-container {
  padding: @spacer * 2;
  padding-bottom: 0;

  :global(.rs-btn) {
    font-size: @font-size-base + 2;
    color: @text;
  }

  .mobile-menu-subheader {
    white-space: normal;
    text-align: left;
  }

  .mobile-menu-item {
    line-height: 24px;
  }
}

.checkout-header {
  svg {
    vertical-align: middle;
  }

  > div {
    padding: 0 @spacer * 4 @spacer * 1.5 @spacer * 3;

    @media @below-lg {
      padding: 0;
      margin: 0;
    }
  }

  .flex-grid {
    .no-flex-wrap;

    @media @below-lg {
      flex-flow: wrap;
    }

    .logo-col {
      @media @below-lg {
        padding: @spacer * 3;
        padding-left: @spacer * 4;
        padding-top: @spacer * 2;
        border-bottom: 1px solid @B200;
        width: 100%;
      }
    }

    .icons-col {
      .flex-grow-1;

      @media @below-lg {
        padding: @spacer * 2 @spacer * 4;
        padding-right: 0;
        flex-grow: 0;

        & > div {
          margin-right: 15px;
        }
      }

      @media @below-md {
        padding: @spacer * 2;
        padding-right: 0;
      }

      @media @below-sm {
        row-gap: @spacer;
      }
    }
  }

  .order-time-icon {
    width: 17px;
    height: 18px;
  }
}

.mobile-drawer-header {
  @icons-horizontal-space: 20px;

  .center-icons-horizontally {
    top: 50%;
    transform: translateY(-50%);
  }

  &:global(.rs-drawer-header) {
    padding: @spacer * 2 @spacer * 7; // @spacer * 7 for truncating the title
    font-family: @headings-font-family;
    justify-content: center;

    :global(.rs-drawer-title) {
      flex-shrink: 1;
      flex-grow: 0;
      line-height: @spacer * 2;
      font-weight: @headings-font-weight;
    }

    :global(.rs-drawer-header-close) {
      .center-icons-horizontally;

      right: @icons-horizontal-space; // Flip the button direction (default is left)
      left: unset;
      padding: 0;

      // Target the close drawer icon
      :global(.rs-icon) {
        font-size: 2em; // Double the icon size according to figma
        font-weight: @headings-font-weight;
      }
    }

    svg {
      color: @headings-color;
    }
  }

  .back-icon {
    font-size: 2em;
    position: absolute;
    left: @icons-horizontal-space;

    .center-icons-horizontally;
  }
}

.product-count {
  font-size: @font-size-base - 2;
  color: @B800;
  margin-left: @spacer;
  font-weight: 400;
}

.disabled-category {
  .disabled;

  color: @B600 !important;
}
